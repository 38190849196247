<template>
	<svg :width="width" :height="height" />
</template>

<script>
	import * as d3 from "d3";

	export default {
		name: "GaugeMinMax",
		props: ['width', 'height', 'widgetData'],
		data(){
			return {
				svg: null,
				margin: 10
			}
		},
		computed: {
			size(){
				return Math.min((this.width / this.data.length- 2*this.margin), this.height * 1.25) 
			},
			data(){
				if (this.widgetData){
					let res = []
					this.widgetData.items.forEach(item=>{
						res.push({x: item.values[0],y: item.values[1]})
					})
					return res
				}else{
					return  []
				}

			},
			params(){
				if (this.widgetData){
					return this.widgetData.params
				}else{
					return  {}
				}
			}
		},
		watch: {
			width(){
				this.initSvg()
			}
		},
		methods: {
			drawFGauge(gauge, viewport, text,color, index){
				let value = (gauge.y - this.params.min) / this.params.max
				let id = Math.random().toString(36).slice(2);

				let arc = d3.arc()
					.innerRadius(0)
					.outerRadius(this.size / 2)
					.startAngle(-Math.PI/2 - 1/12 * Math.PI)
				
				viewport.append("clipPath")
					.attr("id", id)
					.append("path")
					.datum({endAngle: -Math.PI/2 - 1/12 * Math.PI})
					.attr("d", arc)
					.transition()
					.duration(1000)
					.attrTween("d", (ed)=> {
							let interpolate = d3.interpolate(ed.endAngle, (-Math.PI/2 - 1/12 * Math.PI) + (value * (Math.PI + 2/12 * Math.PI)) );
							return (t)=>{
								ed.endAngle = interpolate(t)
								text.text(parseInt(parseInt(gauge.y)* t))
								return arc(ed)
							}
					})
				viewport.append('circle')
					.attr("clip-path", "url(#"+id+")")
					.attr('fill', 'none')
					.attr("stroke", color)
					.attr('stroke-width', this.size * 0.50)
					.attr('r', this.size / 2)
					.attr('cx', 0)
					.attr('cy', 0)

			},
			drawBGauge(gauge,viewport, index){
				let id = Math.random().toString(36).slice(2);
				let full_arc = d3.arc()
					.innerRadius(0)
					.outerRadius(this.size / 2)
					.startAngle( -Math.PI/2 - 1/12 * Math.PI)
					.endAngle(Math.PI/2 + 1/12 * Math.PI)
				
				viewport.append("clipPath")
					.attr("id", id)
					.append("path")
					.attr("d", full_arc)
					.attr('fill', 'white')

				viewport.append('circle')
					.attr('fill', 'none')
					.attr("stroke", 'var(--v-cell2-base)')
					.attr('stroke-width',this.size * 0.50)
					.attr('r', this.size / 2)
					.attr('cx', 0)
					.attr('cy', 0)
				viewport.attr("clip-path", "url(#"+id+")")				
			},
			drawText(gauge,viewport,color, index){
				let txt = viewport.append('text')
					.text(parseInt(gauge.y))
					.attr("font-size", this.size / 5)
					.attr('fill', color)
					.attr("style", "text-anchor: middle; dominant-baseline: central")

				let maxX = Math.cos(Math.PI/2 + 1/12 * Math.PI) * -this.size * 1.4
				let maxY = Math.sin(Math.PI/2 + 1/12 * Math.PI) * this.size * .16

				viewport.append('text')
					.text(parseInt(this.params.max))
					.attr("font-size", this.size / 11)
					.attr('fill', color)
					.attr("style", "text-anchor: middle; dominant-baseline: central")
					.attr('transform', `translate(${maxX} ${maxY})`)

				let minX = Math.cos(-Math.PI/2 + 1/12 * Math.PI) * -this.size * 1.4
				let minY = Math.sin(-Math.PI/2 + 1/12 * Math.PI) * -this.size * .16

				viewport.append('text')
					.text(parseInt(this.params.min))
					.attr("font-size", this.size / 11)
					.attr('fill', color)
					.attr("style", "text-anchor: middle; dominant-baseline: central")
					.attr('transform', `translate(${minX} ${minY})`)
				return txt
				
			},
			initSvg(){
				this.svg = d3.select(this.$el)
				this.svg.selectAll('g').remove()
				let posX = (this.width / this.data.length)
				this.data.forEach((gauge, index)=>{
					let color = this.randomColor()
					let viewport = this.svg.append('g')
						.attr('transform', `translate(${ posX / 2 + index * posX}, ${this.height / 1.5})`)
					let gaugeViewport = viewport.append("g")
					let valuesViewport = viewport.append("g")
					this.drawBGauge(gauge, gaugeViewport, index)
					let text = this.drawText(gauge,valuesViewport, color, index)
					this.drawFGauge(gauge,gaugeViewport, text, color, index)
				})
				
			},
			randomColor(){
				var letters = '456789ABCDEF';
				var color = '#';
				for (var i = 0; i < 6; i++) {
					color += letters[Math.floor(Math.random() * 12)];
				}

				return color;
			}

			
		},
		mounted(){
			this.initSvg()
		}
	}
</script>

<style scoped>

</style>
